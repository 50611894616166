import React, { useState } from "react"
import axios from "axios"
import {
  AiOutlineMail as EmailIcon,
  AiOutlinePhone as PhoneIcon,
} from "react-icons/ai"

const Contact = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [area, setArea] = useState("")
  const [phone, setPhone] = useState("")
  const [requirement, setRequirement] = useState("")

  const [progress, setProgress] = useState(false)
  const [success, setSuccess] = useState(false)

  const isDisabled = !name || !area || !phone || !requirement

  const handleSubmit = async e => {
    if (isDisabled) return
    setProgress(true)
    e.preventDefault()
    await axios.post("/api/send-mail", {
      userEmail: email,
      fields: [
        {
          key: "Name",
          value: name,
        },
        {
          key: "Email",
          value: email,
        },
        {
          key: "Phone number",
          value: phone,
        },
        {
          key: "Area",
          value: area,
        },
        {
          key: "Requirement",
          value: requirement,
        },
      ],
    })
    setProgress(false)
    setSuccess(true)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-5 offset-lg-1 d-flex align-items-center">
          <div>
            <h2>Contact Us</h2>
            <p>
              Interested in our services?
              <br />
              Send us a message and we will get back to you within 24 hours.
            </p>
            <p>Or you can reach us via:</p>
            <p className={"m-0"}>
              <a href="tel:+94760950900">
                <PhoneIcon />
                &nbsp;&nbsp;0760 950 900
              </a>
            </p>
            {/*<p className={"m-0"}>*/}
            {/*  <a href="tel:+94762919090">*/}
            {/*    <PhoneIcon />*/}
            {/*    &nbsp;&nbsp;076 291 9090*/}
            {/*  </a>*/}
            {/*</p>*/}
            <p className="m-0">
              <a href="mailto:info@owita.lk">
                <EmailIcon />
                &nbsp;&nbsp;info@owita.lk
              </a>
            </p>
          </div>
        </div>
        <div className="col-sm-10 col-md-6 col-lg-5 col-xl-4">
          <form action="#" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name">Area *</label>
              <input
                type="text"
                className="form-control"
                placeholder={"Please mention the nearest city"}
                value={area}
                onChange={e => setArea(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name">Phone Number *</label>
              <input
                type="text"
                className="form-control"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name">Email Address</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name">Requirement *</label>
              <textarea
                className="form-control"
                value={requirement}
                onChange={e => setRequirement(e.target.value)}
              />
            </div>
            <button
              className="btn btn-primary"
              type={"submit"}
              disabled={progress || success || isDisabled}
            >
              <Label progress={progress} success={success} />
            </button>
            {success && (
              <small className="text-success d-block mt-3">
                Thank you. One of our agents will get back to you within 24
                hours.
              </small>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact

const Label = ({ success = false, progress = false }) => {
  if (success) {
    return "Submitted"
  }
  if (progress) {
    return "Sending..."
  }
  return "Inquire"
}
