import React from "react"
import Slider from "react-slick"
import Image1 from "./1.jpg"
import Image2 from "./2.jpg"
import Image3 from "./3.jpg"
import Image4 from "./4.jpg"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const slides = [
  {
    image: Image1,
    title: "Protective Poly Tunnels",
    description: "Best quality poly tunnels from Owita",
  },
  {
    image: Image2,
    title: "Hydroponic Systems",
    description: "Best hydroponic home gardening solutions from Owita Organics",
  },
  {
    image: Image3,
    title: "Urban Home Gardens",
    description: "Best solutions for your home garden",
  },
  {
    image: Image4,
    title: "On-demand Agricultural Consultancy",
    description:
      "Best in class agricultural consultancy for your agricultural projects",
  },
]

const Services = () => {
  return (
    <div className="container my-5 pb-5">
      <h2 className={"text-center mb-4"}>Our Solutions &amp; Services</h2>

      <Slider {...settings}>
        {slides.map((slide, idx) => (
          <div className="text-center px-3" key={idx}>
            <img src={slide.image} alt="" className={"w-100"} />
            <h5 className={"my-2"}>{slide.title}</h5>
            <p>{slide.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Services
