import React from "react"
import { FaRegThumbsUp as ListIcon } from "react-icons/fa"
import Image1 from "./images/1.jpg"
import Image2 from "./images/2.jpg"
import Image3 from "./images/3.jpg"
import Image4 from "./images/4.jpg"
import Image5 from "./images/5.jpg"
import Image6 from "./images/6.jpg"

const features = [
  "Protect your crops from excess heat, sunlight, environmental changes (i.e heavy wind, storms etc.)",
  "Control growing environment with temperature control, drip irrigation",
  "Protect your crops from PESTs and animal attacks",
  "Option to grow with our expertise on how to Organically grow in Greenhouses/ Poly tunnels",
  "Customized solutions with experienced & friendly staff along with TRUST of Owita brand.",
]

const WhyUs = () => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-6 col-xl-5 offset-xl-1 d-flex align-items-center">
          <div>
            <h2>Why Choose Us?</h2>
            <p className="h5 my-4">
              With best in class expertise in Sri Lanka:
            </p>
            <ul className={"list-unstyled"}>
              {features.map(feature => (
                <li className={"d-flex align-items-top my-2"} key={feature}>
                  <ListIcon
                    className={"mr-2 mt-1"}
                    style={{ minWidth: "16px" }}
                  />{" "}
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row gutter-6">
            <div className="col">
              <img src={Image1} alt="" className={"w-100 img-thumbnail mb-2"} />
              <img src={Image2} alt="" className={"w-100 img-thumbnail mb-2"} />
              <img src={Image3} alt="" className={"w-100 img-thumbnail mb-2"} />
            </div>
            <div className="col">
              <img src={Image4} alt="" className={"w-100 img-thumbnail mb-2"} />
              <img src={Image5} alt="" className={"w-100 img-thumbnail mb-2"} />
              <img src={Image6} alt="" className={"w-100 img-thumbnail mb-2"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyUs
