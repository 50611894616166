import React from "react"
import Layout from "../../Layout"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import HeroImage from "./hero-image.jpg"
import Contact from "./_Contact"
import Services from "./_Services"
import WhyUs from "./_WhyUs"

const ModernAgroGardensPage = () => {
  return (
    <Layout>
      <Seo title="Modern Agro Gardens" />
      <Breadcrumbs title={"Modern Agro Gardens"} />

      <div className="container">
        <img src={HeroImage} alt="" className={"d-block w-100"} />
      </div>

      <div className="container text-center my-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-7">
            <h2 className={"h3"}>
              With years of Trust built with Owita, now we bring Modern Agro
              Garden Solutions to you.
            </h2>
            <br />
            <p>
              With best experts in the country, we provide highly reliable and
              effective solutions for your home and industrial Agro Garden
              needs.
            </p>
            <p className={"h5"}>Come, partner with us. Let's grow together.</p>
          </div>
        </div>
      </div>

      <WhyUs />

      <Services />

      <Contact />
    </Layout>
  )
}

export default ModernAgroGardensPage
